<template>
  <div>
    <div style="display: flex;
    align-items: center;">
      <div class="circleBox">
        <span class="circle circle-green">高</span>
        <div :style="{height:arrowHeight+'px'}">
          <span
            class="moveUp"
            :style="{height:arrowHeight+'px'}"
          ></span>
        </div>

        <span class="circle circle-red">低</span>
      </div>
      <div class="etcBox">
        <div
          v-for="(item,index) in defaultJobEtcList"
          :key="index"
          style="margin-bottom:10px;"
        >
          <el-input
            :disabled="isInfo"
            v-model="item.jobEtc"
            class="etcInput"
          ></el-input>&nbsp;&nbsp;=>
          <el-input
            :disabled="isInfo"
            v-model="item.minScore"
            class="etcInput"
          ></el-input>&nbsp;~
          <el-input
            :disabled="isInfo"
            v-model="item.maxScore"
            class="etcInput"
          ></el-input>
          <el-button
            :disabled="isInfo"
            type="text"
            v-show="index>1 && !isInfo"
            icon="el-icon-delete"
            class="deletejob"
            @click="delDefalut(index)"
          ></el-button>
        </div>
      </div>
    </div>
    <div class="clearBtn">
      <el-button
        v-if="!isInfo"
        type="text"
        icon="el-icon-plus"
        @click="addDefaultEtc"
      >添加职等</el-button>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    defaultJobEtcList: {
      Type: Array,
      default: () => { }
    },
    isInfo: Boolean
  },
  watch: {
    defaultJobEtcList () {
      this.getHeight();
    }
  },
  data () {
    return {
      arrowHeight: 20
    };
  },
  mounted () {
    this.getHeight();
  },
  methods: {
    addDefaultEtc () {
      this.$emit('addDefaultEtc');
    },
    delDefalut (index) {
      this.$emit('delDefalut', index);
    },
    getHeight () {
      // console.log(this.defaultJobEtcList.length);
      if (this.defaultJobEtcList) {
        this.arrowHeight = this.defaultJobEtcList.length * 55 - 90;// 50为每个div的高度加margin,80为圆点高度加margin
      }

    }
  }
};
</script>
<style lang="scss" scoped>
.deletejob {
  margin-left: 10px;
  color: red;
  font-size: 22px;
  vertical-align: middle;
}
.circleBox {
  // float: left;
  text-align: center;
  // margin-top: 10px;
}
.circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: block;
  line-height: 30px;
  text-align: center;
  color: #fff;
}
.circle-green {
  background: rgba(7, 204, 95, 0.8);
}
.circle-red {
  background: rgba(255, 69, 0, 1);
  // margin-top: -17px;
}
.etcBox {
  margin-left: 20px;
  // float: left;
  margin-top: 10px;
}
.etcInput {
  width: 70px;
}
.clearBtn {
  clear: both;
  margin-left: 50px;
}
.moveUp {
  /*箭头占位符，规定宽高等*/
  width: 10px;
  height: 100%;
  margin: 5px 0;
  display: inline-block;
  position: relative;
  cursor: pointer;
  top: 5px;
}
.moveUp:before,
.moveUp:after {
  /*伪元素相同属性提取*/
  content: "";
  border-color: transparent; /*边框颜色透明*/
  border-style: solid;
  position: absolute; /*绝对定位，分别定位before和after描绘的内容*/
}
.moveUp:before {
  /*伪元素before绘制箭头尾部矩形*/
  border: none;
  background: #c0c4cc;
  height: 100%;
  width: 30%;
  top: -10px;
  left: 3px;
}
.moveUp:after {
  /*伪元素after绘制箭头的头部三角形*/
  left: -2px;
  bottom: 3px;
  border-width: 7px;
  border-top-color: #c0c4cc;
}
</style>
