<template>
  <div class="planInfoBox" :style="$route.name=='MatchplanInfo'?'margin:30px':''">
    <div class="app-container">
      <div class="titlebox">
        <el-button size="medium" type="text" @click="$router.go(-1)"
          >返回</el-button
        >
        <div class="title">{{ title }}</div>
      </div>
      <el-form
        :model="planForm"
        ref="planFrom"
        style="background:#fff;padding: 10px 18px 0;"
      >
        <el-divider />
        <el-form-item label="计划名称" prop="name">
          <el-input v-model.trim="planForm.name" style="width: 34%;" maxlength="20" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="起止时间" prop="datetime">
          <el-date-picker
            v-model="planForm.datetime"
            type="datetimerange"
            style="width: 34%;"
            value-format="yyyy-MM-dd HH:mm:ss"
            format="yyyy-MM-dd HH:mm"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="graduationOptions"
          >
          </el-date-picker>
        </el-form-item>
        <el-divider style="margin:20px 0" />
        <el-form-item label="评价模型">
          <div
            style="margin-left:70px;"
            v-for="(jobmodel, index) in planForm.jobFitModelRequests"
            :key="index"
          >
            <el-select
              v-model="jobmodel.modelId"
              @change="updateModelIdList"
              style="margin:0 10px 0 0"
              size="small"
            >
              <el-option
                v-for="(model, mIndex) in modelList"
                :key="mIndex"
                :value="model.id"
                :disabled="modelIdList.includes(model.id)"
                :label="model.name"
              ></el-option>
            </el-select>
            <el-tag
              style="margin-right:10px;margin-bottom: 10px; margin-top:10px;"
              closable
              @close="delTag(familyIndex, jobmodel, familyId)"
              v-for="(familyId, familyIndex) in jobmodel.familyNameList"
              :key="familyIndex"
              >{{ familyId }}</el-tag
            >
            <el-dropdown trigger="click" style="margin:0 10px 0">
              <el-button icon="el-icon-plus" type="text"
                >添加适用职族</el-button
              >
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="(family, fIndex) in familyList"
                  :key="fIndex"
                  :disabled="disableFamily.includes(family.name)"
                  @click.native.stop="handleRowClick(family, jobmodel)"
                  :command="family"
                  >{{ family.name }}</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
            <el-checkbox
              :true-label="1"
              :false-label="0"
              @change="handelEtc(jobmodel)"
              v-model="jobmodel.isCustomeEtc"
              >自定义职等划分</el-checkbox
            >
            <el-button
              type="text"
              v-show="planForm.jobFitModelRequests.length > 1"
              icon="el-icon-delete"
              class="deletejob"
              @click="deletejobModel(index)"
            ></el-button>
            <etcbox
              v-show="jobmodel.isCustomeEtc == 1"
              style="margin-top: 10px;"
              :inInfo="false"
              @addDefaultEtc="addJobEtc(jobmodel)"
              @delDefalut="delJobetc($event, jobmodel)"
              :defaultJobEtcList="jobmodel.jobEtcRequests"
            ></etcbox>
          </div>
          <el-button
            type="text"
            icon="el-icon-plus"
            style="margin-left:70px"
            @click="addjobModel"
            >添加评价模型</el-button
          >
        </el-form-item>
        <el-form-item label="职等划分" v-show="Modelshow!==planForm.jobFitModelRequests.length">
          <div style="margin-left:70px;">
            <etcbox
              @addDefaultEtc="addDefaultEtc"
              :inInfo="false"
              @delDefalut="delDefalut"
              :defaultJobEtcList="planForm.defaultJobEtcList"
            ></etcbox>
          </div>
        </el-form-item>
        <el-divider style="margin:20px 0" />
        <el-form-item label="评价范围">
          <el-radio v-model="planForm.jobFitScopeRequest.companyType" label="0"
            >{{ comName }}
          </el-radio>
          <el-radio
            v-if="comId == '1'"
            v-model="planForm.jobFitScopeRequest.companyType"
            label="1"
            >客户公司</el-radio
          >
          <el-select
            v-show="planForm.jobFitScopeRequest.companyType == 1"
            v-model="planForm.jobFitScopeRequest.customerComId"
            @change="getStaffOrgList"
            placeholder="请选择"
          >
            <el-option
              v-for="item in companyList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <div class="ml checkBox" style="margin-top:10px;">
            <el-checkbox
              :true-label="1"
              :false-label="0"
              @change="isBydepat"
              v-model="planForm.jobFitScopeRequest.isByDepartment"
              >按部门</el-checkbox
            >
            <el-tag
              v-show="planForm.jobFitScopeRequest.companyType == 0"
              style="margin-left:10px; margin-top:10px; margin-bottom:10px;"
              closable
              @close="delOrg(orgIndex)"
              v-for="(org, orgIndex) in planForm.jobFitScopeRequest.orgNameList"
              :key="orgIndex"
              >{{ org }}</el-tag
            >
            <depts
              v-show="
                planForm.jobFitScopeRequest.companyType == 0 &&
                  planForm.jobFitScopeRequest.isByDepartment == 1
              "
              btnText="添加部门"
              :setChecks="planForm.jobFitScopeRequest.orgIds"
              :checkStrictly="false"
              @updateTag="updateDept"
              :showStaff="false"
            ></depts>
            <el-select
              v-show="
                planForm.jobFitScopeRequest.companyType == 1 &&
                  planForm.jobFitScopeRequest.isByDepartment == 1
              "
              style="margin-left:10px;"
              v-model="planForm.jobFitScopeRequest.toplevelDeptname"
              clearable
              placeholder="请选择"
            >
              <el-option
                v-for="(item, index) in staffOrgOne"
                :key="index"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
            <el-select
              v-show="
                planForm.jobFitScopeRequest.companyType == 1 &&
                  planForm.jobFitScopeRequest.isByDepartment == 1
              "
              style="margin-left:10px;"
              v-model="planForm.jobFitScopeRequest.seclevelDeptname"
              clearable
              placeholder="请选择"
            >
              <el-option
                v-for="(item, index) in staffOrgTwo"
                :key="index"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>
          <div class="ml" v-show="planForm.jobFitScopeRequest.companyType == 0">
            <el-checkbox
              style="margin-right:30px;"
              v-model="checkAllJob"
              @change="checkJob"
              >全部</el-checkbox
            >
            <el-checkbox-group
              style="display:inline-block"
              v-model="planForm.jobFitScopeRequest.jobstatusOptions"
            >
              <el-checkbox
                v-for="(status, staIndex) in statusList"
                :key="staIndex"
                :label="status.dictId"
                >{{ status.dictName }}</el-checkbox
              >
            </el-checkbox-group>
          </div>
          <div class="ml" v-show="planForm.jobFitScopeRequest.companyType == 0">
            <el-checkbox
              style="margin-right:30px;"
              v-model="checkedAll"
              @change="checkAll"
              >全部</el-checkbox
            >
            <el-checkbox-group
              style="display:inline-block"
              v-model="planForm.jobFitScopeRequest.hireformOptions"
            >
              <el-checkbox
                v-for="(status, staIndex) in hireFormList"
                :key="staIndex"
                :label="status.dictId"
                >{{ status.dictName }}</el-checkbox
              >
            </el-checkbox-group>
          </div>
          <div class="ml" style="margin-bottom:20px;margin-top:20px;">
            <el-date-picker
              v-model="planForm.jobFitScopeRequest.entrytimeLimit"
              :picker-options="datePickerOption"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="选择日期"
            >
            </el-date-picker>
            之后入职的员工不参加评价（含当天）
          </div>
          <div class="ml">
            <el-date-picker
              v-model="planForm.jobFitScopeRequest.assesstimeLimit"
              :picker-options="datePickerOption"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="选择日期"
            >
            </el-date-picker>
            之后已评价过的员工不参加评价（含当天）
          </div>
        </el-form-item>
        <el-divider style="margin:20px 0" />
        <el-form-item label="评价人">
          <div class="etcBox">
            <div>
              <el-input
                style="width:150px"
                maxlength="7"
                v-model="planForm.jobFitAssessorRequests[0].assessorName"
                class="etcInput"
              ></el-input
              >&nbsp;&nbsp;=>
              <el-input
                style="width:100px"
                :disabled="true"
                class="etcInput"
                value="员工本人"
              ></el-input>
            </div>
            <div
              v-for="(item, index) in planForm.jobFitAssessorRequests"
              :key="index"
              style="margin-bottom:10px;"
            >
              <div v-if="item.assessorType != 1">
                <el-input
                  style="width:150px"
                  maxlength="7"
                  v-model="item.assessorName"
                  class="etcInput"
                ></el-input
                >&nbsp;&nbsp;=>
                <el-select
                  v-model="item.assessorType"
                  @change="handleAsschange"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="ass in assessorList"
                    :key="ass.dictId"
                    :label="ass.dictName"
                    :disabled="
                      ass.dictId == '1' ||
                        assessorTypeList.indexOf(ass.dictId) > -1 ||
                        (planForm.jobFitScopeRequest.companyType == 1 &&
                          (ass.dictId == '3' || ass.dictId == '4'))
                    "
                    :value="ass.dictId"
                  >
                  </el-option>
                </el-select>
                <el-tag
                  style="margin-right:10px;margin-left:10px; margin-bottom: 10px;"
                  closable
                  @close="delstaff(staffIndex, item)"
                  v-for="(staff, staffIndex) in item.assessorNameList"
                  :key="staffIndex"
                  >{{ staff.staffName }}</el-tag
                >
                <el-button
                  v-if="item.assessorType == '5'"
                  type="text"
                  icon="el-icon-plus"
                  @click="handelStaffDialog(index)"
                  >添加指定人</el-button
                >
                <staffList
                  @getStaff="getStaff"
                  :userList="item.assessorNameList"
                  :index="String(staffIndex)"
                  :staffDialog.sync="staffDialog"
                ></staffList>
                <el-button
                  type="text"
                  v-show="index > 0"
                  icon="el-icon-delete"
                  class="deletejob"
                  @click="delStaffList(index)"
                ></el-button>
              </div>
            </div>
            <div class="clearBtn" style="margin-left:0px;">
              <el-button type="text" icon="el-icon-plus" @click="addAssorList"
                >添加评价人</el-button
              >
            </div>
          </div>
        </el-form-item>
        <el-form-item label="确认人">
          <el-tag
            v-show="planForm.confirmer"
            style="margin-left:20px;"
            closable
            @close="delComfirmer"
            >{{ comfirmer }}</el-tag
          >
          <el-button
            v-show="!planForm.confirmer"
            style="margin-left:20px;"
            type="text"
            icon="el-icon-plus"
            @click="addComfirmer"
            >添加指定人</el-button
          >
          <staffList
            @getStaff="getComfirmer"
            :userList="confirmerList"
            :isOnly="true"
            :staffDialog.sync="confirmerDialog"
          ></staffList>
        </el-form-item>
        <el-divider style="margin:20px 0" />
        <el-form-item label="备注">
          <el-input
            style="width:60%;margin-left:px;"
            class="input_textarea"
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 4 }"
            placeholder="请输入内容"
            v-model="planForm.remark"
            maxlength="255"
            show-word-limit
          >
          </el-input>
        </el-form-item>
        <el-divider style="margin:20px 0" />
        <el-form-item label="是否启动">
          <el-switch
            active-value="1"
            inactive-value="0"
            v-model="planForm.beginStatus"
          >
          </el-switch>
        </el-form-item>
        <div style="text-align:center;margin-bottom:20px;">
          <el-button class="btn_foot" @click="$router.go(-1)">取消</el-button>
          <el-button
            :loading="creatLoad"
            class="btn_foot"
            type="primary"
            @click="savePlan"
            >创建</el-button
          >
        </div>
      </el-form>
    </div>
  </div>
</template>
<script>
import * as api from '@/api/peopelPostMatch';
import etcbox from '@/components/peoplePostMatch/etcBox';
import depts from '@/components/departs';
import staffList from '@/components/departs/staffList.vue';
import { getCompanyList } from '@/api/customer';
export default {
  components: {
    etcbox,
    depts,
    staffList
  },
  data() {
    return {
      creatLoad: false,
      checkedAll: false,
      checkAllJob: false,
      Modelshow: 0,
      title: '创建人岗匹配计划',
      planForm: {
        name: '',
        datetime: [],
        startTime: '',
        endTime: '',
        jobFitModelRequests: [
          {
            modelId: '',
            isCustomeEtc: 0,
            familyIds: [],
            familyNameList: [],
            jobEtcRequests: []
          }
        ],
        jobFitScopeRequest: {
          companyType: '0',
          isByDepartment: '0',
          orgNameList: [],
          orgIds: [],
          jobstatusOptions: [],
          hireformOptions: [],
          customerComId: '',
          toplevelDeptname: '',
          seclevelDeptname: '',
          entrytimeLimit: '',
          assesstimeLimit: ''
        },
        defaultJobEtcList: [
          {
            jobEtc: 'A',
            minScore: '80',
            maxScore: '100'
          },
          {
            jobEtc: 'B',
            minScore: '60',
            maxScore: '79'
          },
          {
            jobEtc: 'C',
            minScore: '46',
            maxScore: '59'
          },
          {
            jobEtc: 'D',
            minScore: '0',
            maxScore: '45'
          }
        ],
        jobFitAssessorRequests: [
          {
            assessorName: '自评',
            assessorType: '1',
            assessorIds: [],
            assessorNameList: []
          }
        ],
        confirmer: '',
        remark: '',
        beginStatus: ''
      },
      modelList: [],
      familyList: [],
      familyIdObj: [],
      statusList: [],
      hireFormList: [],
      // orgObj: [],
      staffOrgOne: [],
      staffOrgTwo: [],
      assessorList: [],
      staffDialog: false,
      staffList: [],
      staffIndex: '',
      isComfirmer: false,
      comfirmer: '',
      type: '',
      planId: '',
      companyList: [],
      comName: '',
      comId: '',
      assessorTypeList: [],
      disableFamily: [],
      modelIdList: [],
      confirmerDialog: false,
      confirmerList: [],
      datePickerOption: {
        disabledDate: (time) => time.getTime() > Date.now()
      },
      graduationOptions: {
        disabledDate: (time) => time.getTime() < Date.now() - 3600 * 1000 * 24
      }
    };
  },
  mounted() {
    this.statusList = this.$store.state.app.dict.filter((item) =>
       item.groupId == 'status' && item.dictId != '3' // 过滤掉离职
    );
    this.hireFormList = this.$store.state.app.dict.filter((item) => item.groupId == 'informal');
    this.assessorList = this.$store.state.app.dict.filter((item) => item.groupId == 'AssessorType');
    this.type = this.$route.query.type;
    if (this.type == 'edit' || this.type == 'copy') {
      this.planId = this.$route.query.id;
    }
    this.getModel();
    this.getFamilyList();
    this.getConpanys();
    this.$nextTick(() => {
      this.comName = sessionStorage.getItem('comName');
      this.comId = sessionStorage.getItem('comId');
      if (this.type == 'edit' || this.type == 'copy') {
        this.getPlanInfo();
      }
    });
  },
  methods: {
    getConpanys() {
      getCompanyList({
        request: {
          name: '',
          pageNo: 1,
          pageSize: 9999
        }
      }).then((res) => {
        this.companyList = res.list;
      });
    },
    getPlanInfo() {
      api.getPlanInfo({ request: { id: this.planId } }).then((res) => {
        this.planForm = Object.assign(this.planForm, res);
        if (this.planForm.jobFitScopeRequest.customerComId) {this.getStaffOrgList(this.planForm.jobFitScopeRequest.customerComId);}
        //  this.planForm.jobFitAssessorRequests[this.staffIndex].assessorNameList = []
        this.planForm.jobFitAssessorRequests.forEach((item, cindex) => {
          if (item.assessorNameList) {
            const list = item.assessorNameList;

            item.assessorNameList = [];
            list.forEach((el, index) => {
              this.planForm.jobFitAssessorRequests[
                cindex
              ].assessorNameList.push({
                staffName: el,
                staffId: item.assessorIds[index]
              });
            });
          }
        });
        for (const asses of this.planForm.jobFitAssessorRequests) {
          if (asses.assessorType != '5') {
            this.assessorTypeList.push(asses.assessorType);
          }
        }

        // 校验模型删除后的回显
        const tempModel = this.modelList.map((v) => v.id);

        for (const model of this.planForm.jobFitModelRequests) {
          if (!tempModel.includes(model.modelId)) {
            model.modelId = '';
          }
          this.disableFamily = this.disableFamily.concat(model.familyNameList);
          model.isCustomeEtc = parseInt(model.isCustomeEtc);
        }
        this.planForm.jobFitScopeRequest.isByDepartment = parseInt(
          this.planForm.jobFitScopeRequest.isByDepartment
        );
        if (this.type == 'edit') {
          this.planForm.datetime.push(this.planForm.startTime);
          this.planForm.datetime.push(this.planForm.endTime);
          this.comfirmer = this.planForm.confirmerName;
        }
        if (this.type == 'copy') {
          this.comfirmer = this.planForm.confirmerName;
          this.planForm.name = '';
          this.planForm.startTime = '';
          this.planForm.endTime = '';
          this.planForm.remark = '';
        }
      });
    },
    getFamilyList() {
      this.$axios
        .post('/v1/profession/family/list', {
          request: {
            name: '',
            updateTimeBefore: '',
            updateTimeEnd: '',
            pageNo: 1,
            pageSize: 999
          }
        })
        .then((res) => {
          this.familyList = res.list;
        });
    },
    changeShoworHidden() {
      this.Modelshow = 0;
        this.planForm.jobFitModelRequests.map((it) => {
          if (it.isCustomeEtc) {
            this.Modelshow++;
          }
        });
    },
    getStaffOrgList(comId) {
      api.getStaffOrgList({ request: { customerComId: comId } }).then((res) => {
        if (res) {
          this.staffOrgOne = res.departmentNames;
          this.staffOrgTwo = res.departmentTwos;
        }
      });
    },
    getModel() {
      const params = {
        request: {
          pageNo: 1,
          pageSize: 9999,
          name: ''
        }
      };

      api.getList(params).then((res) => {
        if (res.list) {
          this.modelList = res.list;
        }
      });
    },
    updateModelIdList() {
      this.modelIdList = [];
      for (const model of this.planForm.jobFitModelRequests) {
        this.modelIdList.push(model.modelId);
      }
    },
    updateFamilyList(index) {
      for (const family of this.planForm.jobFitModelRequests[index]
        .familyNameList) {
        const deIndex = this.disableFamily.indexOf(family);

        this.disableFamily.splice(deIndex, 1);
      }
    },
    addjobModel() {
      this.updateModelIdList();
      this.planForm.jobFitModelRequests.push({
        modelId: '',
        isCustomeEtc: 0,
        familyIds: [],
        familyNameList: [],
        jobEtcRequests: []
      });
    },
    deletejobModel(index) {
      this.updateModelIdList();
      this.updateFamilyList(index);
      this.planForm.jobFitModelRequests.splice(index, 1);
    },
    // 添加默认职等
    addDefaultEtc() {
      this.planForm.defaultJobEtcList.push({
        jobEtc: '',
        minScore: '',
        maxScore: ''
      });
    },
    // 删除添加的默认职等
    delDefalut(index) {
      this.planForm.defaultJobEtcList.splice(index, 1);
    },
    handleRowClick(item, model) {
      if (!model.familyNameList) {
        model.familyNameList = [];
        model.familyIds = [];
      }
      model.familyNameList.push(item.name);
      model.familyIds.push(item.id);
      this.disableFamily.push(item.name);
    },
    delTag(index, model, delName) {
      model.familyNameList.splice(index, 1);
      model.familyIds.splice(index, 1);
      const deIndex = this.disableFamily.indexOf(delName);

      this.disableFamily.splice(deIndex, 1);
    },
    handelEtc(model) {
      if (model.isCustomeEtc == 1) {
          model.jobEtcRequests = [
          {
            jobEtc: '',
            minScore: '',
            maxScore: ''
          },
          {
            jobEtc: '',
            minScore: '',
            maxScore: ''
          }
        ];
      } else {
        model.jobEtcRequests = [];
      }
      this.changeShoworHidden();
    },
    // 自定义职等添加
    addJobEtc(model) {
      model.jobEtcRequests.push({
        jobEtc: '',
        minScore: '',
        maxScore: ''
      });
    },
    handleAsschange(val) {
      if (val != 5) {
        this.planForm.jobFitAssessorRequests.assessorNameList = [];
        this.planForm.jobFitAssessorRequests.assessorIds = [];
      }
    },
    // 自定义职等删除
    delJobetc(index, model) {
      model.jobEtcRequests.splice(index, 1);
    },
    isBydepat(val) {
      if (!val) {
        this.planForm.jobFitScopeRequest.orgIds = [];
        this.planForm.jobFitScopeRequest.orgNameList = [];
      }
    },
    updateDept(data) {
      // this.orgObj = data;
      this.planForm.jobFitScopeRequest.orgIds = [];
      this.planForm.jobFitScopeRequest.orgNameList = [];
      for (const org of data) {
        this.planForm.jobFitScopeRequest.orgIds.push(org.id);
        this.planForm.jobFitScopeRequest.orgNameList.push(org.orgName);
      }
    },
    delOrg(index) {
      // this.orgObj.splice(index, 1);
      this.planForm.jobFitScopeRequest.orgIds.splice(index, 1);
      this.planForm.jobFitScopeRequest.orgNameList.splice(index, 1);
    },
    addAssorList() {
      for (const asses of this.planForm.jobFitAssessorRequests) {
        if (asses.assessorType != '5') {
          this.assessorTypeList.push(asses.assessorType);
        }
      }
      this.planForm.jobFitAssessorRequests.push({
        assessorName: '',
        assessorType: '',
        assessorIds: [],
        assessorNameList: []
      });
    },
    getComfirmer(data) {
      //   if (data.length > 1) {
      //     this.$message.error('只能选择一位确认人')
      //     return;
      //   } else {
      if (data.length == 0) {
        this.confirmerList = [];
        return;
      }
      this.confirmerList = data;
      this.planForm.confirmer = data[0].staffId;
      this.comfirmer = data[0].staffName;
      this.isComfirmer = false;
      //   }
    },
    getStaff(data) {
      this.planForm.jobFitAssessorRequests[this.staffIndex].assessorIds = [];
      this.planForm.jobFitAssessorRequests[
        this.staffIndex
      ].assessorNameList = [];
      for (const staff of data) {
        this.planForm.jobFitAssessorRequests[
          this.staffIndex
        ].assessorNameList.push({
          staffName: staff.staffName,
          staffId: staff.staffId
        });
        this.planForm.jobFitAssessorRequests[this.staffIndex].assessorIds.push(
          staff.staffId
        );
      }
    },
    delstaff(index, model) {
      //   model.assessorNameList.splice(index, 1);
      model.assessorNameList.splice(index, 1);
      model.assessorIds.splice(index, 1);
    },
    handelStaffDialog(index) {
      this.staffDialog = true;
      this.staffIndex = index;
    },
    delStaffList(index) {
      this.planForm.jobFitAssessorRequests.splice(index, 1);
      this.assessorTypeList = [];
      for (const asses of this.planForm.jobFitAssessorRequests) {
        if (asses.assessorType != '5') {
          this.assessorTypeList.push(asses.assessorType);
        }
      }
    },
    addComfirmer() {
      this.confirmerDialog = true;
      this.isComfirmer = true;
    },
    delComfirmer() {
      this.planForm.confirmer = '';
      this.comfirmer = {};
      this.confirmerList = [];
    },
    checkAll(val) {
      if (val) {
        this.planForm.jobFitScopeRequest.hireformOptions = [];
        for (const check of this.hireFormList) {
          this.planForm.jobFitScopeRequest.hireformOptions.push(check.dictId);
        }
      } else {
        this.planForm.jobFitScopeRequest.hireformOptions = [];
      }
    },
    checkJob(val) {
      if (val) {
        this.planForm.jobFitScopeRequest.jobstatusOptions = [];
        for (const check of this.statusList) {
          this.planForm.jobFitScopeRequest.jobstatusOptions.push(check.dictId);
        }
      } else {
        this.planForm.jobFitScopeRequest.jobstatusOptions = [];
      }
    },
    savePlan() {
      const valid = this.validateForm();

      if (valid) {
        this.planForm.startTime = this.planForm.datetime[0];
        this.planForm.endTime = this.planForm.datetime[1];
        this.creatLoad = true;
        if (this.type == 'add' || this.type == 'copy') {
          api.savePlan({ request: this.planForm }).then((res) => {
            this.creatLoad = false;
            if (res.code == 0) {
              const tip = this.type == 'add' ? '新增计划成功' : '副本创建成功';

              this.$message.success(tip);
              this.$router.go(-1);
            }
          });
        } else {
          this.planForm.id = this.planId;
          api.editPlan({ request: this.planForm }).then((res) => {
            this.creatLoad = false;
            if (res.code == 0) {
              this.$message.success('修改计划成功');
              this.$router.go(-1);
            }
          });
        }
      }
    },
    validateForm() {
      if (!this.planForm.name) {
        this.$message.error('请输入计划名称');
        return false;
      }
      if (this.planForm.datetime.length == 0) {
        this.$message.error('请选择起止时间');
        return false;
      }
      for (const test of this.planForm.jobFitModelRequests) {
        if (!test.modelId) {
          this.$message.error('请选择模型');
          return false;
        }
        if (test.familyIds.length == 0) {
          this.$message.error('请选择适用职族');
          return false;
        }
        for (const model of this.planForm.jobFitModelRequests) {
          if (model.jobEtcRequests) {
            for (const etc of model.jobEtcRequests) {
              if (!etc.jobEtc) {
                this.$message.error('职等不能为空');
                return false;
              }
              if (!etc.minScore || !etc.minScore) {
                this.$message.error('职等区间不能为空');
                return false;
              }
            }
          }
        }
      }
      for (const asses of this.planForm.jobFitAssessorRequests) {
        if (!asses.assessorName) {
          this.$message.error('评价人别名不能为空');
          return false;
        }
        if (!asses.assessorType) {
          this.$message.error('请选择评价类型');
          return false;
        }
        if (asses.assessorType == '5') {
          if (asses.assessorIds.length == 0) {
            this.$message.error('请选择评价指定人');
            return false;
          }
        }
      }
      if (this.planForm.confirmer == '') {
        this.$message.error('请选择确认人');
        return false;
      }
      // if (!this.planForm.jobFitScopeRequest.entrytimeLimit) {
      //   this.$message.error('请选择最迟入职时间')
      //   return false
      // }
      // if (!this.planForm.jobFitScopeRequest.assesstimeLimit) {
      //   this.$message.error('请选择最迟评价时间')
      //   return false
      // }
      for (const etc of this.planForm.defaultJobEtcList) {
        if (!etc.jobEtc) {
          this.$message.error('职等不能为空');
          return false;
        }
        if (!etc.minScore || !etc.minScore) {
          this.$message.error('职等区间不能为空');
          return false;
        }
      }
      if (this.planForm.jobFitScopeRequest.companyType == '1') {
        if (this.planForm.jobFitScopeRequest.customerComId == '') {
          this.$message.error('请选择客户公司');
          return false;
        }
      }
      return true;
    }
  }
};
</script>
<style lang="scss" scoped>
.planInfoBox {
  .app-container {
    color: #484848;
    padding: 0;
    background-color: #f6f7fb;
    .titlebox {
      text-align: center;
      line-height: 17px;
      background: #fff;
      font: bold 18px/25px 'Microsoft YaHei';
      padding: 14px 40px;
      margin-bottom: 20px;
      > .el-button {
        float: left;
        color: #484848;
        transform: translateY(-8px);
        font-size: 16px;
      }
    }
    .deletejob {
      margin-left: 10px;
      color: red;
      font-size: 22px;
      vertical-align: middle;
    }
    .jobmodelBox {
      margin: 10px;
    }
    .jobmodelBox + .jobmodelBox {
      margin-left: 70px;
    }
  }
}
.ml {
  margin-left: 70px;
}
.checkBox {
  .el-tag:first-of-type {
    margin-left: 10px;
  }
}
.etcBox {
  margin-left: 20px;
  float: left;
  margin-top: 10px;
}
.etcInput {
  width: 70px;
}
.clearBtn {
  clear: both;
  margin-left: 115px;
}
.btn_foot {
  padding: 10px 45px;
}
</style>
